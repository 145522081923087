/* services-list.css */

.services_list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 1rem;
}

.srvice-card {
  position: relative;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box; /* Include padding in width calculation */
}

.textContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%; /* Adjust the width to the container */
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 1)
  );
  color: white; /* Set text color for better visibility */
}

/* Media query for phones */
@media (max-width: 767px) {
  .srvice-card {
    display: block; /* Stacking elements vertically on phones */
    margin-bottom: 20px; /* Adding some spacing between stacked elements */
    padding: 0.5rem; /* Adjust padding for phones */
  }

  .textContainer {
    padding: 1rem; /* Adjust padding for phones */
  }

  .services_list {
    flex-direction: column;
  }
}
