/* MapboxDirection.css */
.mapboxgl-popup-content {
    background-color: black;
    color: white;
    border-radius: 10px;
    padding: 10px;
  }
  
  .mapboxgl-popup-close-button {
    color: rgb(255, 255, 255);
    border: none;
    border-color: black;
  }
  
  /* Hide Mapbox attribution */
  .mapboxgl-ctrl-logo,
  .mapboxgl-ctrl-attrib {
    display: none !important;
  }

  .travel-time {
    position: absolute;
    top: 10px;
    left: 10px;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    z-index: 1;
  }
  
  .travel-time div {
    margin-bottom: 5px;
  }
  